
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import BarOverlayForm from "./BarOverlayForm.vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import dayjs from "dayjs";
import OverlayPanel from "primevue/overlaypanel";
import Utils from "@/utility/utils";

import { WorkOrder, OperItem, Operation } from "@/types/state/production";
import { WorkCenter } from "@/types/workCenter";
import { WorkOrderObject } from "@/types/state/workOrder";

import { cloneDeep } from "lodash";
import { AccessRights } from "@/types/accessControl/AbstractAccessGetter";

export default defineComponent({
  components: {
    BarOverlayForm,
    Button,
    Calendar,
    OverlayPanel,
  },
  emits: ["save-work-orders"],
  props: {
    workCenterList: {
      type: Array as () => WorkCenter[],
      required: true,
      default: () => [] as WorkCenter[],
    },
  },
  inject: ["getAccessRights"],
  data() {
    return {
      chartData: {} as any,
      toDate: new Date(),
      fromDate: new Date(),
      barSelected: null as any,
      scrollTimer: null as any,
      loadingScheduleSave: false,
      loadingScheduleSearch: false,
      rowHeight: 40,
      accessRights: this.getAccessRights as unknown as AccessRights,
    };
  },
  computed: {
    ...mapGetters({
      getSchedulingFromDate: "workorder/getSchedulingFromDate",
      getSchedulingToDate: "workorder/getSchedulingToDate",
      getSelectedWorkOrders: "workorder/getSelectedWorkOrders",
      getOrderToAdd: "workorder/getOrderToAdd",
      getScheduledWorkOrders: "workorder/getScheduledWorkOrders",
      getSchedulingGroupItems: "woControl/getSchedulingGroupItems",
      getSelectedScheduleGroup: "workorder/getSelectedScheduleGroup",
      getCalendarTimeIncrement: "woControl/getCalendarTimeIncrement",
      getGanttStatusItems: "woControl/getGanttStatusItems",
    }),

    tableSize(): string {
      const dateRangeNumber = dayjs(this.getSchedulingToDate.toString())
        .startOf("day")
        .diff(
          dayjs(this.getSchedulingFromDate.toString()).startOf("day"),
          "days",
        );
      if (dateRangeNumber >= 3) {
        return `${dateRangeNumber * 40}rem`;
      }
      return "";
    },
    timeAxis(): string {
      const hourDiff = dayjs(this.getSchedulingToDate.toString()).diff(
        dayjs(this.getSchedulingFromDate.toString()),
        "hours",
      );
      if (hourDiff === 1) {
        return "1 hour";
      } else if (hourDiff <= 24) {
        return hourDiff + " hours";
      } else {
        return `${Math.floor(hourDiff / 24)} ${
          Math.floor(hourDiff / 24) === 1 ? "day" : "days"
        }`;
      }
    },
    hasChangeAccess(): boolean {
      return this.accessRights.change;
    },
    disableSaveButton(): boolean {
      if (!this.hasChangeAccess) {
        return true;
      }

      return !this.getSelectedWorkOrders.some((order: WorkOrderObject) => {
        const oldOperations = order.oldRecord.oper_items;
        const newOperations = order.record.oper_items;

        if (oldOperations.length !== newOperations.length) {
          return true;
        }

        return oldOperations.some((oldOperation: OperItem, index: number) => {
          const newOperation = newOperations[index];
          return (
            oldOperation.oper_start_date !== newOperation.oper_start_date ||
            oldOperation.oper_start_time !== newOperation.oper_start_time ||
            oldOperation.oper_comp_date !== newOperation.oper_comp_date ||
            oldOperation.oper_comp_time !== newOperation.oper_comp_time ||
            oldOperation.work_center !== newOperation.work_center ||
            oldOperation.operation_desc !== newOperation.operation_desc
          );
        });
      });
    },
  },
  async mounted() {
    this.handleClickScheduleSearch();
    if (!Object.keys(this.chartData).length && this.workCenterList.length) {
      this.formatDataToChart();
    }

    // Odd date serialization to show the date in the correct format. The date is serialized in the store as a string.
    this.setSchedulingFromDate(new Date(this.getSchedulingFromDate));
    this.setSchedulingToDate(new Date(this.getSchedulingToDate));
  },
  watch: {
    getSelectedWorkOrders: {
      handler() {
        this.formatDataToChart();
      },
      deep: true,
    },
    getSelectedScheduleGroup() {
      this.handleFetchScheduledWorkOrders();
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      putSelectedWorkOrders: "workorder/putSelectedWorkOrders",
      updateSelectedWorkOrder: "workorder/updateSelectedWorkOrder",
      removeSelectedWorkOrderById: "workorder/removeSelectedWorkOrderById",
      setSchedulingFromDate: "workorder/setSchedulingFromDate",
      setSchedulingToDate: "workorder/setSchedulingToDate",
      clearSelectedWorkOrders: "workorder/clearSelectedWorkOrders",
      fetchScheduledWorkOrders: "workorder/fetchScheduledWorkOrders",
    }),
    getGanttStatusLabel(status: string): string {
      switch (status) {
        case "N":
          return "New";
        case "O":
          return "Open";
        case "R":
          return "Released";
        case "P":
          return "Pulled";
        case "S":
          return "Started";
        case "C":
          return "Closed";
        case "F":
          return "Finalized";
        default:
          return "";
      }
    },
    saveChartData() {
      this.loadingScheduleSave = true;
      this.putSelectedWorkOrders().finally(() => {
        this.loadingScheduleSave = false;
        this.$emit("save-work-orders");
        this.formatDataToChart();
      });
    },
    dragEndBar(event: any) {
      const movedBars = event.movedBars;
      movedBars.forEach((value: any, key: any) => {
        if (this.getCalendarTimeIncrement > 1) {
          const startDate = dayjs(key.startDate).startOf("minute");
          const startMinutes = startDate.minute();
          const roundedStartMinutes =
            Math.round(startMinutes / this.getCalendarTimeIncrement) *
            this.getCalendarTimeIncrement;
          const snappedStartDate = startDate
            .minute(roundedStartMinutes)
            .toDate();

          const endDate = dayjs(key.endDate).startOf("minute");
          const endMinutes = endDate.minute();
          const roundedEndMinutes =
            Math.round(endMinutes / this.getCalendarTimeIncrement) *
            this.getCalendarTimeIncrement;
          const snappedEndDate = endDate.minute(roundedEndMinutes).toDate();

          key.startDate = snappedStartDate;
          key.endDate = snappedEndDate;
        }

        const workOrderInProgress = this.getSelectedWorkOrders.find(
          (order: WorkOrderObject) =>
            order.record.wo_id === key.barData.workOrderId,
        );
        if (workOrderInProgress) {
          const operation = workOrderInProgress.record.oper_items.find(
            (item: OperItem) => item.oper === key.barData.operation.oper,
          );
          if (
            operation &&
            this.isOperationDateChanged(operation, key.startDate, key.endDate)
          ) {
            this.updateOperation(
              operation,
              key.startDate,
              key.endDate,
              operation.work_center,
              operation.operation_desc,
            );
            this.updateSelectedWorkOrder({
              record: workOrderInProgress.record,
            });
          }
        } else {
          const workOrder = this.getScheduledWorkOrders.find(
            (order: WorkOrder) => order.wo_id === key.barData.workOrderId,
          );
          const oldRecord = cloneDeep(workOrder);
          if (workOrder) {
            const operation = workOrder.oper_items.find(
              (item: OperItem) => item.oper === key.barData.operation.oper,
            );
            if (
              operation &&
              this.isOperationDateChanged(operation, key.startDate, key.endDate)
            ) {
              this.updateOperation(
                operation,
                key.startDate,
                key.endDate,
                operation.work_center,
                operation.operation_desc,
              );
              this.updateSelectedWorkOrder({ record: workOrder, oldRecord });
            }
          }
        }
      });

      this.formatDataToChart();
    },
    handleFetchScheduledWorkOrders() {
      const startDate = dayjs(this.getSchedulingFromDate).format("MM-DD-YYYY");
      const endDate = dayjs(this.getSchedulingToDate).format("MM-DD-YYYY");
      const fitleredWCIDs = this.getSchedulingGroupItems
        ?.find(
          (group: any) =>
            group.scheduling_group === this.getSelectedScheduleGroup,
        )
        ?.scheduling_workcenter_items.map(
          (item: any) => item.scheduling_workcenter,
        )
        .filter((item: any) => item);
      this.fetchScheduledWorkOrders({
        Correls:
          "so_cust_name so_li_req_date so_li_urgent so_li_bom_count subassembly",
        Workcenters: fitleredWCIDs,
        OperationStartDate: startDate,
        OperationCompDate: endDate,
      }).finally(() => {
        this.formatDataToChart();
      });
    },
    addItemToChart(event: any, row: any) {
      if (!this.getOrderToAdd) {
        this.addNotification({
          type: "warning",
          title: "Warning",
          message:
            "Issue adding Work Order to the scheduler. Please try again.",
        });
        return;
      }

      const { workOrder, operation } = this.getOrderToAdd;

      const oldRecord = cloneDeep(workOrder);

      const workCenterIdList = this.workCenterList.map(
        (center: any) => center.work_center,
      );
      const count = workOrder.oper_items.filter((item: OperItem) =>
        workCenterIdList.includes(item.work_center),
      ).length;

      if (operation) {
        const newOperation = new Operation();
        newOperation.initFromOperItem(operation);

        const startDate = new Date(event.datetime);
        const endDate = new Date(
          dayjs(event.datetime)
            .add(newOperation.totalRunTime, "hours")
            .toDate(),
        );
        this.updateOperation(
          operation,
          startDate,
          endDate,
          row.work_center,
          row.description,
        );

        const index = workOrder.oper_items.findIndex(
          (item: OperItem) => item.oper === operation.oper,
        );

        if (index > -1) {
          workOrder.oper_items[index] = operation;
        } else {
          workOrder.oper_items.push(operation);
        }

        if (
          this.getSelectedWorkOrders.find(
            (order: WorkOrderObject) => order.record.wo_id === workOrder.wo_id,
          )
        ) {
          this.updateSelectedWorkOrder({ record: workOrder });
        } else {
          this.updateSelectedWorkOrder({ record: workOrder, oldRecord });
        }
      } else if (count == 1) {
        const mainOperationIndex = workOrder.oper_items.findIndex(
          (item: OperItem) =>
            workCenterIdList.includes(item.work_center) &&
            !item.oper.includes("-"),
        );
        if (mainOperationIndex > -1) {
          const operationCopy = new Operation();
          operationCopy.initFromOperItem(
            workOrder.oper_items[mainOperationIndex],
          );
          const startDate = new Date(event.datetime);
          const endDate = new Date(
            dayjs(event.datetime)
              .add(operationCopy.totalRunTime, "hours")
              .toDate(),
          );
          this.updateOperation(
            workOrder.oper_items[mainOperationIndex],
            startDate,
            endDate,
            row.work_center,
            row.description,
          );
          this.updateSelectedWorkOrder({ record: workOrder, oldRecord });
        }
      } else {
        const operations = workOrder.oper_items.filter((item: OperItem) =>
          workCenterIdList.includes(item.work_center),
        );

        operations.forEach((operation: OperItem, index: number) => {
          const operationCopy = new Operation();
          operationCopy.initFromOperItem(operation);
          const startDate = new Date(event.datetime);
          const endDate = new Date(
            dayjs(event.datetime)
              .add(operationCopy.totalRunTime, "hours")
              .toDate(),
          );
          this.updateOperation(
            operation,
            startDate,
            endDate,
            operation.work_center,
            operation.operation_desc || row.description,
          );
        });
        this.updateSelectedWorkOrder({ record: workOrder, oldRecord });
      }

      this.formatDataToChart();
    },
    isOperationDateChanged(
      operation: OperItem,
      startDate: string,
      endDate: string,
    ): boolean {
      const newStartDate = Utils.formatDate(startDate);
      const newEndDate = Utils.formatDate(endDate);
      const newStartTime = this.formatDateTime(startDate);
      const newEndTime = this.formatDateTime(endDate);
      return (
        operation.oper_start_date !== newStartDate ||
        operation.oper_start_time !== newStartTime ||
        operation.oper_comp_date !== newEndDate ||
        operation.oper_comp_time !== newEndTime
      );
    },
    updateOperation(
      operation: OperItem,
      startDate: Date,
      endDate: Date,
      workCenter: string,
      description: string,
    ) {
      // Update the operation in the work order
      operation.oper_start_date = Utils.formatDate(startDate);
      operation.oper_start_time = this.formatDateTime(startDate);
      operation.oper_comp_date = Utils.formatDate(endDate);
      operation.oper_comp_time = this.formatDateTime(endDate);
      operation.work_center = workCenter;
      operation.operation_desc = description;
    },
    onMouseDownBar(event: any) {
      (this.$refs as any).barpopover.hide();
      this.barSelected = {
        initialDatetime: event.datetime,
      };
    },
    onMouseUpBar(event: any) {
      // We use this function to start the edition of the bar
      // We check if the initial dateTime value of the mousedown is the same of this mouseUp to know if it's just a click or a drag
      if (
        this.barSelected &&
        this.barSelected.initialDatetime === event.datetime
      ) {
        let workOrder = this.getSelectedWorkOrders.find(
          (order: WorkOrderObject) =>
            order.record.wo_id === event.bar.barData.workOrderId,
        );
        if (!workOrder) {
          workOrder = this.getScheduledWorkOrders.find(
            (order: WorkOrder) => order.wo_id === event.bar.barData.workOrderId,
          );
        } else {
          workOrder = workOrder.record;
        }
        this.barSelected.bar = {
          startDate: event.bar.startDate,
          endDate: event.bar.endDate,
          oper: event.bar.barData.operation.oper,
          isGeneric: event.bar.isGeneric,
          workCenter: event.bar.barData.operation.work_center,
          barLabel: event.bar.barData.operation.operation_desc,
          modified: this.isOperationModified(
            workOrder,
            event.bar.barData.operation,
          ),
        };
        this.barSelected.workOrder = workOrder;
        this.barSelected.event = event.e;
        this.barSelected.currentTarget = event.e.currentTarget;
        (this.$refs as any).barpopover.toggle(event.e);
      }
    },
    formatDateSelectedToChart(event: any) {
      return dayjs(event).toDate();
    },
    formatDataToChart() {
      this.rowHeight = 40;

      this.chartData = {};
      this.workCenterList.forEach((workCenter, index) => {
        this.chartData[workCenter.work_center] = {
          workCenter,
          bars: [],
          rowColor: Utils.getColorByIndex(index),
        };
      });

      const workOrdersInProgress = this.getSelectedWorkOrders.map(
        (wo: WorkOrderObject) => {
          return wo.record;
        },
      );

      const ids = workOrdersInProgress.map((wo: WorkOrder) => wo.wo_id);

      // Add work orders that have been modified by users
      workOrdersInProgress.forEach((workOrder: WorkOrder) => {
        workOrder?.oper_items?.forEach((operation: OperItem, index: number) => {
          if (
            operation.oper &&
            operation.oper_start_time &&
            operation.oper_comp_time
          ) {
            this.addOperationToChart(workOrder, index);
          }
        });
      });

      const workOrdersScheduled = this.getScheduledWorkOrders.filter(
        (wo: WorkOrder) => !ids.includes(wo.wo_id),
      );
      // Add work orders that are not being modified by users and are already scheduled
      workOrdersScheduled.forEach((workOrder: WorkOrder) => {
        workOrder?.oper_items?.forEach((operation: OperItem, index: number) => {
          this.addOperationToChart(workOrder, index);
        });
      });

      this.$nextTick(() => {
        this.rowHeight =
          document.getElementsByClassName("gantt-row-data")[0].clientHeight;
      });
    },
    addOperationToChart(workOrder: WorkOrder, index: number) {
      const operation = workOrder.oper_items[index];
      const finishedQuantity = parseFloat(operation.curr_oper_qty) === 0;
      let desc;
      if (operation.oper.includes("-")) {
        desc = operation.operation_desc;
      } else {
        desc =
          workOrder.wo_id +
          " - " +
          workOrder.part_no +
          " - " +
          workOrder.description;
      }

      if (operation.work_center in this.chartData) {
        const modified = this.isOperationModified(workOrder, operation);

        const matchingStatus = this.getGanttStatusItems.find(
          (item: any) => item.gantt_status === workOrder.status,
        );

        this.chartData[operation.work_center].bars.push({
          startDate: dayjs(
            `${operation.oper_start_date} ${
              operation.oper_start_time ?? "00:00"
            }`,
          ).format("YYYY-MM-DD HH:mm"),
          endDate: dayjs(
            `${operation.oper_comp_date} ${operation.oper_comp_time ?? "00:00"}`,
          ).format("YYYY-MM-DD HH:mm"),
          status: workOrder.status,
          isGeneric: operation.oper.includes("-"),
          barData: { workOrderId: workOrder.wo_id, operation },
          modified: modified ? "pi pi-pencil" : "",
          ganttBarConfig: {
            id: workOrder.wo_id + "-" + operation.oper,
            hasHandles: false,
            immobile: !this.hasChangeAccess || finishedQuantity,
            label: desc,
            style: {
              background: modified
                ? "yellow"
                : matchingStatus.gantt_hex
                  ? matchingStatus.gantt_hex
                  : this.chartData[operation.work_center].rowColor,
              borderRadius: "6px",
              color: "black",
              fontSize: "14px",
            },
          },
        });
      }
    },
    isOperationModified(workOrder: WorkOrder, operation: OperItem): boolean {
      const workOrderInProgress = this.getSelectedWorkOrders.find(
        (order: WorkOrderObject) => order.record.wo_id === workOrder.wo_id,
      );

      if (!workOrderInProgress) {
        return false;
      }

      const oldOperations = workOrderInProgress?.oldRecord.oper_items;

      const oldOperation = oldOperations?.find(
        (item: OperItem) => item.oper === operation.oper,
      );

      if (!oldOperation) {
        return true;
      }

      return (
        oldOperation.oper_start_date !== operation.oper_start_date ||
        oldOperation.oper_start_time !== operation.oper_start_time ||
        oldOperation.oper_comp_date !== operation.oper_comp_date ||
        oldOperation.oper_comp_time !== operation.oper_comp_time ||
        oldOperation.work_center !== operation.work_center ||
        oldOperation.operation_desc !== operation.operation_desc
      );
    },
    onSaveBarData(event: any, workOrder: any, notes: any) {
      const isSelected = this.getSelectedWorkOrders.find(
        (order: WorkOrderObject) => order.record.wo_id === workOrder.wo_id,
      );
      let oldRecord = cloneDeep(workOrder);
      workOrder.notes = notes;
      let operation = workOrder.oper_items.find(
        (item: OperItem) => item.oper === event.oper,
      );
      if (operation) {
        const relatedOperations = workOrder.oper_items.filter(
          (item: OperItem) => item.oper && item.oper.includes(event.oper + "-"),
        );
        relatedOperations.forEach((op: OperItem) => {
          op.work_center = event.workCenter;
        });
        this.updateOperation(
          operation,
          event.startDate,
          event.endDate,
          event.workCenter,
          event.barLabel,
        );
      } else {
        workOrder.oper_items.push({
          oper: event.oper,
          oper_start_date: Utils.formatDate(event.startDate),
          oper_start_time: this.formatDateTime(event.startDate),
          oper_comp_date: Utils.formatDate(event.endDate),
          oper_comp_time: this.formatDateTime(event.endDate),
          work_center: event.workCenter,
          operation_desc: event.barLabel,
        });
      }
      if (isSelected) {
        this.updateSelectedWorkOrder({ record: workOrder });
      } else {
        this.updateSelectedWorkOrder({ record: workOrder, oldRecord });
      }
      (this.$refs as any).barpopover.hide();
      this.$nextTick(() => {
        this.formatDataToChart();
      });
    },
    onRemoveBar(event: any, workOrder: any) {
      // Need to check if the workOrder is in the queue to update.
      this.updateSelectedWorkOrder({ record: workOrder });

      const operationIndex = workOrder.oper_items.findIndex(
        (item: OperItem) => item.oper === event.oper,
      );
      const operation = workOrder.oper_items[operationIndex];
      if (operation.oper.includes("-")) {
        workOrder.oper_items.splice(operationIndex, 1);
      } else {
        operation.oper_start_date = "";
        operation.oper_start_time = "";
        operation.oper_comp_date = "";
        operation.oper_comp_time = "";
      }

      if (!event.oper.includes("-")) {
        const relatedOperations = workOrder.oper_items
          .filter(
            (item: OperItem) =>
              item.oper && item.oper.includes(event.oper + "-"),
          )
          .map((op: OperItem) => op.oper);
        workOrder.oper_items = workOrder.oper_items.filter(
          (item: OperItem) => !relatedOperations.includes(item.oper),
        );
      }

      const editedBar = { ...workOrder };
      this.updateSelectedWorkOrder({ record: editedBar });

      (this.$refs as any).barpopover.hide();
      this.formatDataToChart();
    },
    onAddOperation() {
      (this.$refs as any).barpopover.hide();
      const eventRecreated = {
        ...this.barSelected.event,
        currentTarget: this.barSelected.currentTarget,
      };

      const mainId = this.barSelected.bar.oper.split("-")[0];

      const relatedOperations = this.barSelected.workOrder.oper_items.filter(
        (item: OperItem) =>
          item.oper.includes(mainId + "-") || item.oper === mainId,
      );

      const operationIndicies = relatedOperations.map((item: OperItem) =>
        item.oper.includes(mainId + "-")
          ? parseInt(item.oper.split("-")[1])
          : 0,
      );

      const maxId = Math.max(...operationIndicies);

      const lastOperationByTime = relatedOperations.reduce(
        (prev: any, curr: any) => {
          if (prev.oper_comp_date && curr.oper_comp_date) {
            return dayjs(prev.oper_comp_date).isAfter(
              dayjs(curr.oper_comp_date),
            )
              ? prev
              : curr;
          } else if (prev.oper_comp_date) {
            return prev;
          } else {
            return curr;
          }
        },
      );

      this.barSelected.bar = {
        startDate: lastOperationByTime.oper_comp_date
          ? dayjs(
              `${lastOperationByTime.oper_comp_date} ${lastOperationByTime.oper_comp_time}`,
            )
          : dayjs().toDate(),
        endDate: lastOperationByTime.oper_comp_date
          ? dayjs(
              `${lastOperationByTime.oper_comp_date} ${lastOperationByTime.oper_comp_time}`,
            )
              .add(1, "hour")
              .toDate()
          : dayjs().toDate(),
        oper: mainId + "-" + (maxId + 1),
        isGeneric: true,
        workCenter: this.barSelected.bar.workCenter,
        barLabel: "",
      };
      this.barSelected.isEditing = false;
      // We hide and then show up the popover again here to avoid a visual bug that wasn´t allowing it to reposition correctly after a change of size
      this.$nextTick(() => {
        (this.$refs as any).barpopover.toggle(eventRecreated);
      });
      this.formatDataToChart();
    },
    scheduleToday() {
      this.setSchedulingFromDate(dayjs().startOf("d").toDate());
      this.setSchedulingToDate(dayjs().endOf("d").toDate());
    },
    formatDateTime(date: Date | string): string {
      return dayjs(date).format("HH:mm");
    },
    async handleClickScheduleSearch() {
      this.loadingScheduleSearch = true;
      const startDate = dayjs(this.getSchedulingFromDate).format("MM-DD-YYYY");
      const endDate = dayjs(this.getSchedulingToDate).format("MM-DD-YYYY");
      const fitleredWCIDs = this.getSchedulingGroupItems
        ?.find(
          (group: any) =>
            group.scheduling_group === this.getSelectedScheduleGroup,
        )
        ?.scheduling_workcenter_items.map(
          (item: any) => item.scheduling_workcenter,
        )
        .filter((item: any) => item);
      await this.fetchScheduledWorkOrders({
        Correls:
          "so_cust_name so_li_req_date so_li_urgent so_li_bom_count subassembly",
        Workcenters: fitleredWCIDs,
        OperationStartDate: startDate,
        OperationCompDate: endDate,
      }).finally(() => {
        this.formatDataToChart();
        this.loadingScheduleSearch = false;
      });
    },
    onUndoChanges(event: any, workOrder: any) {
      const workOrderInProgress = this.getSelectedWorkOrders.find(
        (order: WorkOrderObject) => order.record.wo_id === workOrder.wo_id,
      );

      if (!workOrderInProgress) {
        return;
      }

      const oldWorkOrder = workOrderInProgress.oldRecord;
      const oldOperation = oldWorkOrder.oper_items.find(
        (item: OperItem) => item.oper === event.oper,
      );

      if (!oldOperation) {
        workOrderInProgress.record.oper_items =
          workOrderInProgress.record.oper_items.filter(
            (item: OperItem) => item.oper !== event.oper,
          );
        return;
      }

      const operationIndex = workOrderInProgress.record.oper_items.findIndex(
        (item: OperItem) => item.oper === event.oper,
      );
      workOrderInProgress.record.oper_items[operationIndex] = oldOperation;

      this.updateSelectedWorkOrder({
        record: cloneDeep(workOrderInProgress.record),
      });

      this.formatDataToChart();
    },
    clearWorkOrdersInProgress() {
      this.clearSelectedWorkOrders();
    },
  },
});
